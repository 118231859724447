// src/polyfills.ts

/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js'; // Included with Angular CLI.

/***************************************************************************************************
 * Add other polyfills as needed.
 * For example, if targeting IE11 or older browsers, you may need the following:
 * import 'classlist.js';  // Run `npm install --save classlist.js`.
 * import 'web-animations-js';  // Run `npm install --save web-animations-js`.
 */

/***************************************************************************************************
 * Application-specific polyfills can be added here.
 */
